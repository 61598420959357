import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const EthicAndCompliance = () => {

    const nameBank = JsonData.Header.nameBank;

    return (
        <div>
            <PageTitle title="Ética e Compliance" />
            <div id="bodyContrato" className="text-start container">
                <h5>POLÍTICA DE COMPLIANCE {nameBank}</h5>
                <p>No {nameBank} atuamos de acordo com as regulamentações e leis aplicáveis ao nosso negócio,
                focados em sempre proporcionar a melhor experiência aos nossos clientes.</p>
                <p>Nossa Política de Compliance define as diretrizes relacionadas ao cumprimento desses requisitos e reforça o nosso compromisso de transparência e excelência no atendimento.</p>
                <p>Por isso, queremos compartilhar com você as nossas diretrizes de Compliance:</p>
                <p>O {nameBank} atua continuamente no desenvolvimento de uma cultura de Compliance para reforçar os princípios do nosso Código de Conduta.</p>
                <p>Atuamos sempre com objetivo de garantir o cumprimento dos requisitos regulatórios e legais aplicáveis ao nosso negócio e clientes.</p>
                <p>O time de Compliance é responsável por coordenar assuntos relacionados a conformidade e auxiliar os clientes a entenderem e cumprirem com o seu papel na nossa cultura de Compliance.</p>
                <p>O time de Compliance presta suporte e reporta periodicamente à Diretoria os assuntos relacionados a conformidade.</p>
                <p>Todos os clientes são responsáveis por zelar pelo cumprimento da Política de Compliance e por promover nossa cultura de Integridade.</p>
                <p>Caso você acredite que outro cliente, ou mesmo o {nameBank}, não esteja seguindo o padrão de
                conduta esperado, conte-nos por meio do Canal de Denúncias do {nameBank}.</p>
            </div>
        </div>
    );
};

export default EthicAndCompliance;