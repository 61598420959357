import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import "../App.css";


const EssentialServices = () => {

    return (
        <div>
            <PageTitle title="Tabela de tarifas" />
            <div id="bodyContrato" className="text-start container">
                <h3>Tabela com os serviços essenciais de conta digital</h3>
                <p>Serviços gratuitos por mês.</p>
                <p>Confecção de cadastro para início de relacionamento.</p>
                <p>Realização de transferências de recursos entre contas pela internet, na
                própria instituição.</p>
                <p>Fornecimento de extrato dos últimos 30 dias</p>
                <p>Fornecimento de extrato de outros períodos</p>
                <p>Fornecimento de extrato contendo a movimentação da conta.</p>
                <p>Realização de consultas mediante utilização da internet sem limite.</p>
                <p>Fornecimento anual de extrato consolidado, discriminando, mês a mês, os valores cobrados no ano anterior relativos às tarifas, juros, encargos moratórios, multas e demais despesas incidentes sobre operações da conta.</p>
                <p>Prestação de qualquer serviço por meios eletrônicos, no caso de contas cujos contratos prevejam utilizar exclusivamente meios eletrônicos.</p>
            </div>
        </div>
    );
};

export default EssentialServices;