import React from "react";

export const LdServices = (props) => {
    return (
        <div id="services" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>para você e para sua empresa</h2>
                </div>
                <div className="row d-flex-start">
                    {props.data
                        ? props.data.line.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                                {" "}
                                <i className={d.icon}></i>
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
                <div className="row d-flex-start">
                    {props.data
                        ? props.data.line2.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                                {" "}
                                <i className={d.icon}></i>
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
                <div className="row d-flex-start">
                    {props.data
                        ? props.data.line3.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                                {" "}
                                <i className={d.icon}></i>
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
            </div>
        </div>
    );
};