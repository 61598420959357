import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const FraudAlert = () => {

    const nameBank = JsonData.Header.nameBank;
    const bankDomain = JsonData.Header.nameBankDomain

    return (
        <div>
            <PageTitle title="ALERTA DE FRAUDES" />
            <div id="bodyContrato" className="text-start container">
                <h5>Cuidado com Golpes!</h5>
                <p>Informamos que a {nameBank} é uma Instituição de pagamentos que têm como clientes empresas, e pessoas físicas e não entramos em contato diretamente para oferecer nossos produtos, os mesmos são oferecidos dentro da nossa plataforma, após a conta ser aprovada pelo nosso compliance.</p>
                <p>Para mais informações, entre em contato com a Central de Atendimento da {nameBank} por meio de nossos canais oficiais, conforme indicado abaixo: </p>
                <p>E-mail: sac@{bankDomain}.com.br</p>
            </div>
        </div>
    );
};

export default FraudAlert;