// import { PageTitle } from "./pageTitle";

import "../App.css";
import { PageTitle } from "./pageTitle";

export const AccountCancellation = () => {
  return (
    <div>
      <PageTitle title="POLÍTICA PARA ENCERRAMENTO DE CONTAS" />
      <div id="bodyContrato" className="container text-start">
        <p></p>

        <p>Prezado(a) cliente!</p>
        <p>
          Segue abaixo o procedimento a ser adotado para encerramento de conta:
        </p>

        <p>
          1 – Em sua conta, basta acessar o perfil de usuário, tem um ícone
          destacado no lado direito da tela DELETAR CONTA em vermelho.
        </p>

        <p>2 – No perfil, selecionar a opção “deletar conta”</p>

        <p>3 – Feito isso, basta concluir o fluxo de encerramento.</p>

        <p>
          4 – Observação: Para que o fluxo seja concluído a conta precisa estar
          sem saldo, ou seja, deve ocorrer a transferência de eventual saldo
          remanescente pelo titular para conta diversa na própria ou em outra
          instituição.
        </p>

        <p>
          5 – Concluído esse fluxo, a solicitação será enviada para o setor
          responsável analisar e, estando de acordo, efetivar o encerramento da
          referida conta.
        </p>

        <p>
          Havendo qualquer pendência, o setor responsável entrará em contato
          para auxiliá-lo. Em caso de dúvida, permanecemos à sua disposição
          através dos nossos canais oficiais!
        </p>
      </div>
    </div>
  );
};
