import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const SecurityInformationPolitical = () => {

    const nameBank = JsonData.Header.nameBank;
    const bankDomain = JsonData.Header.nameBankDomain

    return (
        <div>
            <PageTitle title="POLÍTICA DE SEGURANÇA DA INFORMAÇÃO" />
            <div id="bodyContrato" className="text-start container">
                <h5>POLÍTICA DE SEGURANÇA DA INFORMAÇÃO DA {nameBank}</h5>
                <p>1. Compromisso com a Segurança</p>
                <p>Na {nameBank}, levamos a segurança da informação a sério. Nosso compromisso é proteger a integridade, confidencialidade e disponibilidade de todas as informações que manuseamos, tanto de nossos clientes como da nossa própria empresa.</p>
                <br />
                <p>2. Princípios Chave</p>
                <p>Proteção de Dados: Implementamos as melhores práticas para garantir que os dados dos nossos clientes estejam seguros e protegidos contra acesso não autorizado.</p>
                <p>Gestão de Riscos: Realizamos avaliações periódicas de riscos para identificar e mitigar ameaças à segurança.</p>
                <p>Controle de Acesso: Garantimos que o acesso às informações seja rigorosamente controlado e concedido apenas conforme a necessidade.</p>
                <p>Resposta a Incidentes: Estamos preparados para responder de forma eficaz a qualquer incidente de segurança, minimizando impactos.</p>
                <p>Conformidade e Auditoria: Cumprimos com todas as leis e regulamentos de segurança da informação relevantes, submetendo-nos a auditorias regulares.</p>
                <p>Capacitação e Conscientização: Promovemos uma cultura de segurança entre nossos funcionários através de treinamentos contínuos.</p>
                <br />
                <p>3. Transparência e Confiança</p>
                <p>Acreditamos na transparência como base para a confiança. Estamos sempre abertos a discutir nossas políticas e práticas de segurança com os clientes.</p>
                <br />
                <p>4. Compromisso Contínuo</p>
                <p>Nosso compromisso com a segurança da informação é contínuo. Revisamos e atualizamos nossas políticas e práticas regularmente para enfrentar novos desafios e ameaças no cenário digital.</p>
                <br />
                <p>5. Fale Conosco</p>
                <p>Para mais informações sobre nossas práticas de segurança, por favor, entre em contato conosco através de sac@{bankDomain}.com.br.</p>
            </div>
        </div>
    );
};

export default SecurityInformationPolitical;