import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const RiskPolitical = () => {

    const nameBank = JsonData.Header.nameBank;
    const bankDomain = JsonData.Header.nameBankDomain

    return (
        <div>
            <PageTitle title="POLÍTICA DE GESTÃO DE RISCOS" />
            <div id="bodyContrato" className="text-start container">
                <h5>POLÍTICA DE GESTÃO DE RISCOS DA {nameBank}</h5>
                <p>1. Introdução</p>
                <p>Na {nameBank}, entendemos que a gestão eficaz de riscos é fundamental para o sucesso sustentável do nosso negócio. Nossa Política de Gestão de Riscos estabelece os princípios e diretrizes para identificar, avaliar, gerenciar e monitorar os riscos em todas as nossas operações e decisões estratégicas.</p>
                <br />
                <p>2. Abordagem para a Gestão de Riscos</p>
                <p>Identificação de Riscos: Proativamente identificamos riscos potenciais em todas as áreas de nossa operação, incluindo financeira, tecnológica, operacional e de mercado.</p>
                <p>Avaliação de Riscos: Avaliamos a probabilidade e o impacto de cada risco identificado, utilizando metodologias e ferramentas robustas.</p>
                <br />
                <p>3. Estratégias de Mitigação</p>
                <p>Plano de Mitigação: Desenvolvemos e implementamos estratégias de mitigação para os riscos identificados, buscando reduzir a probabilidade e/ou impacto destes riscos em nossas operações.</p>
                <p>Monitoramento Contínuo: Monitoramos continuamente o ambiente de negócios e nossas operações para detectar mudanças nos riscos e eficácia das estratégias de mitigação.</p>
                <br />
                <p>4. Cultura de Gestão de Riscos</p>
                <p>Capacitação: Promovemos a conscientização e capacitação contínua de nossos colaboradores em gestão de riscos, assegurando que todos compreendam sua importância e como contribuir efetivamente.</p>
                <p>Comunicação e Transparência: Mantemos uma comunicação aberta sobre a gestão de riscos, tanto internamente quanto com stakeholders externos.</p>
                <br />
                <p>5. Governança e Responsabilidade</p>
                <p>Responsabilidades Claras: Definimos claramente as responsabilidades e a estrutura de governança para a gestão de riscos em toda a organização.</p>
                <p>Revisão e Melhoria Contínua: A Política de Gestão de Riscos é regularmente revisada e atualizada para refletir as mudanças no ambiente de negócios e nas melhores práticas do setor.</p>
                <br />
                <p>6. Fale Conosco</p>
                <p>Para mais informações sobre nossa Política de Gestão de Riscos, entre em contato através de sac@{bankDomain}.com.br.</p>
            </div>
        </div>
    );
};

export default RiskPolitical;