import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const ReportingChannel = () => {

    const nameBank = JsonData.Header.nameBank;
    const bankDomain = JsonData.Header.nameBankDomain

    return (
        <div>
            <PageTitle title="Canal de Denúncias" />
            <div id="bodyContrato" className="text-start container">
                <h5>CANAL DE DENÚNCIAS {nameBank}</h5>
                <p>Este canal é exclusivo da {nameBank} e destinado a denúncias anônimas ou identificadas de atos ilícitos ou que violem o nosso código de conduta.</p>

                <p>Casos como fraudes, lavagem de dinheiro, condutas irregulares, corrupção, dentre outras ilicitudes que envolvam a {nameBank} podem ser denunciadas de maneira anônima, prezando pela confidencialidade da identidade do denunciante.</p>
            
                <p>Veja abaixo como realizar uma denúncia</p>

                <p>Se possível, descreva sua denúncia com o maior número de informações e detalhamentos possíveis, como:</p>
                <ul>
                    <li>Onde, quando e como ocorreu ou está ocorrendo o caso denunciado;</li>
                    <li>Quem são as pessoas envolvidas, áreas ou empresas envolvidas na ilicitude;</li>
                    <li>Detalhamento aprofundado dos fatos;</li>
                    <li>Documentos, comprovantes e provas da denúncia.</li>
                </ul>
                <p>E-mail para realizar uma denúncia: contato@{bankDomain}.com.br</p>
            </div>
        </div>
    );
};

export default ReportingChannel;