import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const EsgPolitical = () => {

    const nameBank = JsonData.Header.nameBank;

    return (
        <div>
            <PageTitle title="POLÍTICA DE ESG" />
            <div id="bodyContrato" className="text-start container">
                <h5>POLÍTICA DE ESG DA {nameBank}</h5>
                <p>1. Introdução</p>
                <p>Na {nameBank}, reconhecemos a importância de integrar práticas sustentáveis e responsáveis em todas as nossas operações. Nossa Política de ESG (Environmental, Social, and Governance) reflete nosso compromisso com a sustentabilidade ambiental, a excelência social e a governança corporativa ética.</p>
                <br />
                <p>2. Ambiental</p>
                <p>Sustentabilidade e Inovação: Nos empenhamos para minimizar nosso impacto ambiental, implementando tecnologias e processos que promovem a eficiência energética e a redução de resíduos.</p>
                <p>Consciência Ecológica: Promovemos práticas sustentáveis em nossa cadeia de suprimentos e incentivamos iniciativas verdes em todas as áreas de nossa empresa.</p>
                <br />
                <p>3. Social</p>
                <p>Diversidade e Inclusão: Valorizamos e fomentamos um ambiente de trabalho diversificado e inclusivo, garantindo igualdade de oportunidades para todos os colaboradores.</p>
                <p>Compromisso Comunitário: Estamos engajados em causar impactos sociais positivos, apoiando iniciativas locais e promovendo o desenvolvimento comunitário e a educação financeira.</p>
                <br />
                <p>4. Governança</p>
                <p>Transparência e Ética: Mantemos altos padrões de integridade e ética em todas as nossas operações, garantindo transparência e responsabilidade em nossas práticas de negócios.</p>
                <p>Conformidade Regulatória: Nos comprometemos em atender a todas as regulamentações do setor e em manter uma governança corporativa sólida, que sustente a confiança de nossos stakeholders.</p>
                <br />
                <p>5. Compromisso Contínuo</p>
                <p>A {nameBank} está dedicada a revisar e aprimorar continuamente suas políticas e práticas de ESG, assegurando que elas estejam alinhadas com as necessidades e expectativas de nossos clientes, funcionários e da comunidade.</p>
            </div>
        </div>
    );
};

export default EsgPolitical;