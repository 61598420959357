import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const MoneyPolitical = () => {

    const nameBank = JsonData.Header.nameBank;

    return (
        <div>
            <PageTitle title="POLÍTICA DE ESG" />
            <div id="bodyContrato" className="text-start container">
                <h5>POLÍTICA DE PREVENÇÃO À LAVAGEM DE DINHEIRO E AO FINANCIAMENTO DO TERRORISMO (PLD/CFT) DA {nameBank}</h5>
                <p>1. Introdução</p>
                <p>Na {nameBank}, estamos comprometidos com a integridade e a transparência em todas as nossas operações. Nossa Política de Prevenção à Lavagem de Dinheiro e ao Financiamento do Terrorismo (PLD/CFT) reflete esse compromisso e está alinhada com as melhores práticas globais e regulamentações locais.</p>
                <br />
                <p>2. Compromisso com a Conformidade</p>
                <p>Adesão às Leis e Normas: Cumprimos rigorosamente todas as leis e normas relacionadas à prevenção de lavagem de dinheiro (PLD) e ao combate ao financiamento do terrorismo (CFT) aplicáveis à nossa indústria e jurisdições de operação.</p>
                <p>Parcerias Responsáveis: Trabalhamos apenas com parceiros e clientes que compartilham de nossos valores de integridade e transparência.</p>
                <br />
                <p>3. Identificação e Verificação de Clientes</p>
                <p>Processos de KYC (Conheça Seu Cliente): Implementamos rigorosos procedimentos de KYC para identificar e verificar a identidade de nossos clientes, tanto no início do relacionamento quanto de forma contínua.</p>
                <br />
                <p>4. Monitoramento e Reporte</p>
                <p>Transações Suspeitas: Monitoramos continuamente transações e atividades para identificar e reportar quaisquer operações suspeitas às autoridades competentes.</p>
            </div>
        </div>
    );
};

export default MoneyPolitical;