import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const UserTerms = () => {

    return (
        <div>
            <PageTitle title="Termos de Uso" />
            <div id="bodyContrato" className="text-start container">
                <h5>Termos</h5>
                <p>Ao acessar o site Valour Bank, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda ser responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>

                <h5>Uso de Licença</h5>
                <p>É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Valour Bank, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode:</p>
                <ul>
                    <li>Modificar ou copiar os materiais;</li>
                    <li>Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
                    <li>Tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Valour Bank;</li>
                    <li>Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou</li>
                    <li>Transferir os materiais para outra pessoa ou "espelhe" os materiais em qualquer outro servidor.</li>
                </ul>
                <p>Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida pela Valour Bank a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrônico ou impresso.</p>

                <h5>Isenção de responsabilidade</h5>
                <p>Os materiais no site da Valour Bank são fornecidos "como estão". Valour Bank não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual, ou outra violação de direitos.</p>
                <p>Além disso, a Valour Bank não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais em seu site, ou de outra forma relacionado a esses materiais, ou em sites vinculados a este site.</p>

                <h5>Limitações</h5>
                <p>Em nenhum caso a Valour Bank ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais da Valour Bank, mesmo que a Valour Bank ou um representante autorizado da Valour Bank tenha sido notificado oralmente, ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes, ou incidentais, essas limitações podem não se aplicar a você.</p>

                <h5>Precisão dos materiais</h5>
                <p>Os materiais exibidos no site da Valour Bank podem incluir erros técnicos, tipográficos ou fotográficos. Valour Bank não garante que qualquer material em seu site seja preciso, completo ou atual. Valour Bank pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, Valour Bank não se compromete a atualizar os materiais.</p>

                <h5>Links</h5>
                <p>A Valour Bank não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso pela Valour Bank do site. O uso de qualquer site vinculado é por conta e risco do usuário.</p>

                <h5>Modificações</h5>
                <p>A Valour Bank pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.</p>

                <h5>Lei aplicável</h5>
                <p>Estes termos e condições são regidos e interpretados conforme as leis da Valour Bank e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.</p>
            </div>
        </div>
    );
};

export default UserTerms;